import React from 'react';
import './Body.css';
import logo from '../logo.png';
import playstore from '../playstore.png'
import appstore from '../applestore.png'

const Body = () => {
    return (
        <div className="App-body">
            <img src={logo} className="App-logo" alt="logo" />
            <img src={appstore} className="App-store" alt="app-store" />
            <img src={playstore} className="Play-store" alt="play-store" />
        </div>
    );
};

export default Body;