import React from "react";
const Footer = () => {
    const year = new Date().getFullYear();
    const phone = "713-277-8002"
    const email = "info@shmusik.com"

    return <footer><h3>Contact Us</h3><p>{`Email: ${email}`}</p><p>{`Phone: ${phone}`}</p>
        {`Copyright © Shmusik LLC ${year}`}</footer>;
};

export default Footer;