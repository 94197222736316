import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <div className="header">
        <h1>About Us</h1>
      </div>
      <div className="content">
        <p>
          Shmusik, LLC. is a Social Streaming Entertainment platform built to offer you the best in interactive experiences.
          We are motivated in delivering users a unique experience in terms of social interactions.
          From our live concert streams to the interaction options between users, we are always aspiring to offer the best content and quality to our users.
        </p>
      </div>
    </div>
  );
};

export default About;
