import React from 'react';
import './Contact.css';

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Send email logic here
    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;

    const emailBody = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;

    window.location.href = `mailto:info@shmusik.com?subject=Contact Inquiry&body=${encodeURIComponent(
      emailBody
    )}`;
  };

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      <p>If you have any questions or inquiries, please feel free to reach out to us.</p>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" rows="5" />
        </div>
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default Contact;
