import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import logo from '../logo.png';
import './Login.css';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('isLoggedIn')) {
      console.log('isLoggedIn status is currently true');
    } else {
      console.log('isLoggedIn status is currently false');
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await Auth.signIn(username, password);
      const adminUsers = ['shmusik', 'shmusik_admin', 'shmusik_dev'];

      if (adminUsers.includes(username)) {
        console.log('Logged in successfully');
        setError('');
        localStorage.setItem('isLoggedIn', 'true');
        onLogin();
        navigate('/admin-console');
        navigate(0);
      } else {
        setError('User exists but login not allowed');
      }
    } catch (error) {
      if (error.code === 'UserNotFoundException') {
        setError('User does not exist');
      } else if (error.code === 'NotAuthorizedException') {
        setError('Incorrect username or password');
      } else if (error.code === 'UserNotConfirmedException') {
        setError('User is not confirmed');
      } else {
        setError('Login error');
      }
    }
  };

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo-image" />
      </div>
      <h2>Login</h2>
      {error && <p>{error}</p>}
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
