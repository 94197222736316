import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './AdminConsole.css';

const AdminConsole = () => {
  const [data, setData] = useState([]);
  const appId = 'lien2m5jk5az7gsyp3ikaaxlaa';
  const appKey = 'da2-f3ziqkut2ff6relnqbxvzjqlhe';
  const apiUrl = 'https://xitxijdw4vduvcchnyrvkm7urm.appsync-api.us-east-1.amazonaws.com/graphql';

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = (id) => {
    // Handle the delete functionality for the given id
    console.log(`Delete item with id ${id}`);
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(apiUrl, {
        query: `
          query {
            listReports {
              items{
                id
                postId
                userId
              }
            }
          }
        `,
        // query: `
        //   query {
        //     listUsers {
        //       items{
        //         id
        //         username
        //         profilePictureUrl
        //       }
        //     }
        //   }
        // `,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': appKey,
          'x-app-id': appId,
        },
      });

      console.log('Response data:', response.data);
      setData(response.data.data.listReports.items);
      // setData(response.data.data.listUsers.items);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="admin-console">
      <h1>Admin Console</h1>
      {data ? (
        <table className="admin-table">
          <thead>
            <tr>
              <th>Post Id</th>
              <th>Reporting User</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((report) => (
              <tr key={report.id}>
                <td>{report.postId}</td>
                <td>{report.userId}</td>
                <td>
                  <button onClick={() => handleDelete(report.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
          {/* <thead>
            <tr>
              <th>Username</th>
              <th>Profile Picture URL</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((user) => (
              <tr key={user.id}>
                <td>{user.username}</td>
                <td>
                  <img src={user.profilePictureUrl} alt="Profile" />
                </td>
                <td>
                  <button onClick={() => handleDelete(user.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody> */}
        </table>
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
  
};

export default AdminConsole;
